html body {
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden;
}

.zoobbe-workspace-board-container {
  --card-details-bg: #f0f1f4;
  --card-details-bg: #1d3954;
  // --card-details-bg: #343b42;
  margin-top: 20px;
  overflow-y: hidden !important;

  .hidden {
    display: none !important;
  }

  .zoobbe-board {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
    padding: 0;
    margin: 0;
    // min-width: calc(100% - 20px);

    .zoobbe-action-list {
      flex: 0 0 300px; // Each column will be 280px wide
      box-sizing: border-box; // Ensure padding and border are included in the element's width
      // background: #040404;
      border-radius: 12px 12px 0 0;
      // padding: 15px 8px;
      display: flex;
      flex-direction: column;
      position: relative; // Ensure relative positioning for absolute child elements
      height: 100%;
      border-radius: 12px;
      will-change: transform;
      // overflow: hidden;

      .action-list-content {
        max-height: calc(100% - 15px);
        position: relative;

        .action-list-inner.other-dragging::after {
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: 2;
        }
        .action-list-inner.dragging-start {
          cursor: copy;
        }
      }

      .action-list-inner {
        // background: var(--actionlist-background-color);
        overflow: hidden;
        border-radius: 15px;
        // border: 2px solid var(--actionlist-background-color);
        cursor: move;
        max-height: 100%;
        margin-left: 2px;
        // max-height: calc(100% - 15px);
      }

      .list-header {
        flex: 0 0 auto; // Prevent it from stretching
        margin-bottom: 0px;
        backdrop-filter: blur(5px);
      }
      // .action-list-container {
      //   overflow: -moz-scrollbars-none;
      //   -ms-overflow-style: none;
      //   scrollbar-width: none; /* For Firefox */
      // }

      .action-list-container::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Edge */
      }
      .action-list-container {
        flex: 1 1 auto;
        overflow-y: auto;
        padding-bottom: 0px;
        margin-bottom: 0px;
        // padding: 20px 10px;
        background-color: var(--actionlist-background-color);

        .action-card-list {
          padding: 0 6px 6px;
          padding-bottom: 0px !important;
        }
      }

      .add-new-card-form-button {
        flex: 0 0 auto;
        background: var(--actionlist-background-color);
        padding: 8px 8px 6px 8px;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 12px 12px;
        z-index: 2;
      }
      .add-new-card-form {
        flex: 0 0 auto;
        background: var(--actionlist-background-color);
        padding: 8px 6px 6px 8px;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 12px 12px;
        z-index: 2;
      }
      .add-new-card-form.top-card-form {
        border-radius: 0;
        padding: 8px 0;
      }

      .card-pemalink-wrapper {
        text-decoration: none;
        color: inherit;
        margin-bottom: 3px;
        display: block;
        border-radius: 10px;
        border: 2px solid transparent;
        position: relative;
        overflow: hidden;

        &:first-child {
          margin-top: 5px;
        }
        // &:last-child {
        //   margin-bottom: 5px;
        // }

        .card-pemalink {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: pointer;
        }

        &:hover {
          border-color: var(--focus-outline-color);

          .edit-card {
            display: block;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }

        .edit-card {
          position: absolute;
          z-index: 1;
          right: 5px;
          top: 5px;
          cursor: pointer;
          display: none;

          span {
            color: var(--single-card-text-color);
            font-size: 16px;
            padding: 5px;
            border-radius: 50px;
            transition: 0.3s;
            background-color: var(--small-element-background-color);

            &:hover {
              background: var(--single-card-action-button-hover-color);
              // color: var(--brand-color);
            }
          }
        }
      }
      .card-pemalink-wrapper.edit-mode:hover {
        border-color: transparent;
      }
    }

    &:first-child {
      margin-left: 20px;
    }
  }
}
.edit-card {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: none;

  span {
    color: var(--single-card-text-color);
    font-size: 16px;
    padding: 5px;
    border-radius: 50px;
    transition: 0.3s;
    background-color: var(--small-element-background-color);

    &:hover {
      background: var(--single-card-action-button-hover-color);
      // color: var(--brand-color);
    }
  }
}
.list-header {
  text-align: left;
  margin-bottom: 15px;
  padding: 10px 15px;
  flex: 0 0 auto;
  background: var(--list-header-background-color);
  // background: var(--actionlist-background-color);
  // border-radius: 12px 12px 0 0;

  .list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 8px 0;
    gap: 5px;
    h2 {
      color: var(--single-card-text-color);
      word-break: break-word;
      cursor: text;
    }
  }

  h2 {
    margin: 0;
    font-size: 15px;
    // font-weight: 500;
  }

  p {
    color: var(--single-card-text-color);
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 5px;
  }
}

.card-pemalink-wrapper {
  .zoobbe-card {
    background: var(--card-background-color);
    border-radius: 6px;
    padding: 12px;
    // margin-bottom: 15px;
    position: relative;
    overflow: hidden;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span.card-option-icon {
        display: inline-flex;
        cursor: pointer;
        position: absolute;
        right: 13px;
        top: 8px;
        z-index: 1;

        span {
          color: var(--single-card-text-color);
        }
      }
    }

    .zoobbe-card-cover-image {
      margin-bottom: 6px;
      text-align: center;
      margin-left: -12px;
      margin-top: -12px;
      width: calc(100% + 24px);
      max-height: 260px;
      img.cover-image {
        width: auto;
        max-height: 260px;
        max-width: 100%;
      }
    }

    .avatars {
      display: flex;
      display: flex;
      align-items: center;
      // margin-top: -8px;
      // .image-placeholder,
      // img {
      //   margin-right: -5px;
      // }
    }

    p {
      margin-bottom: 0px;
      margin-top: 0;
      font-size: 14px;
      line-height: 1.4;
      color: var(--single-card-text-color);
      word-break: break-word;
      font-weight: 600;
      font-family: system-ui;
      max-width: calc(100% - 15px);
    }

    .card-labels {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 8px;
      width: calc(100% - 15px);
    }

    .label {
      padding: 0px 12px;
      border-radius: 3px;
      color: var(--label-text-color);
      font-size: 0.75em;
      display: inline-block;
      font-family: system-ui;
      min-height: 5px;
      min-width: 20px;
      background-color: #d1453b;
      opacity: 0.75;
      font-weight: var(--label-font-weight);
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      color: var(--color-white);
    }

    .zoobbe-card-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      flex-wrap: wrap;
      gap: 10px;

      .zoobbe-card-front-badges span svg {
        height: 14px;
      }

      span.card-priority {
        background: #ff858929;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 20px;
        align-items: center;
        border-radius: 50px;
      }

      .zoobbe-card-front-badges,
      .zoobbe-card-front-badges span.badge-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        gap: 4px;
        span {
          font-size: 12.5px;
          color: var(--single-card-text-color);
          font-weight: 600;
        }
      }

      span.badge-card-checklists.badge-icon {
        background: var(--badge-background-color);
        border-radius: 10px;
        padding: 2px 4px;

        span {
          color: var(--label-text-color);
        }
      }

      span.badge-card-attachments.badge-icon .material-icons {
        transform: rotate(45deg);
      }

      span.badge-card-checklists {
        gap: 5px;

        span {
          font-size: 14px;
        }
      }
      .zoobbe-card-front-badges {
        gap: 10px;
        span.card-priority {
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.action-card-list > div:first-child .card-pemalink-wrapper {
  margin-top: -5px !important;
}
.action-card-list > div:last-child .card-pemalink-wrapper {
  margin-bottom: 0px !important;
}

.feature-request {
  .label {
    background-color: #28a745;
  }
}

.improvements {
  .label {
    background-color: #ff7f0e;
  }
}

.avatars {
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    // border: 1px solid #a39ecf;
  }
}

// Workspaces navbar
.zoobbe-workspace-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00000033;
  padding: 10px 20px;
  color: var(--color-white);
  position: relative;
  z-index: 2 !important;
  flex-wrap: wrap;
  gap: 20px;

  > * {
    z-index: 1;
  }

  &::before {
    backdrop-filter: blur(2px);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: var(--navbar-background-color); /* Semi-transparent overlay */
  }

  .zoobbe-navbar-left,
  .zoobbe-navbar-right {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
  }
  .zoobbe-navbar-left {
    gap: 30px;
    li.zoobbe-board-visibility {
      display: flex;
      align-items: center;
      gap: 6px; // Updated from 5px to avoid conflict
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;

      border: none;
      border-radius: 3px;
      height: 32px;
      font-family: system-ui;
      transition: 0.1s;
      font-weight: 600; // Final font-weight after deduplication

      font-family: var(--primary-font-family);

      span.material-symbols-outlined {
        font-size: 18px;
        color: var(--white);
      }
    }

    li.zoobbe-board-visibility.active {
      color: var(--primary-text-color);
      background-color: rgba(11, 102, 255, 0.0509803922);
    }

    li.board-archived-items {
      display: flex;
      align-items: center;
      gap: 6px; // Updated from 5px to avoid conflict
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;

      border: none;
      border-radius: 3px;
      height: 32px;
      font-family: system-ui;
      transition: 0.1s;
      font-weight: 600; // Final font-weight after deduplication

      font-family: var(--primary-font-family);

      span.material-symbols-outlined {
        font-size: 18px;
        color: var(--white);
      }
    }
  }

  .zoobbe-navbar-right {
    gap: 30px;
  }

  .zoobbe-workspace-name {
    font-weight: bold;
  }

  .zoobbe-board-title {
    font-weight: bold;
    height: 30px;
    max-width: 100%;
    width: auto;
    background: transparent;
    border: none;
    font-size: 18px;
    border-radius: 4px;
    color: var(--white);
  }
  input.zoobbe-board-title {
    padding: 0 5px;

    &:focus {
      outline: 2px solid var(--brand-color);
      background-color: #08283b;
    }
  }

  .zoobbe-star {
    cursor: pointer;
    display: flex;
    span {
      font-size: 18px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .zoobbe-workspace {
    margin-left: 20px;
    cursor: pointer;
  }

  .zoobbe-board-button,
  .zoobbe-power-ups,
  .zoobbe-filters,
  .zoobbe-clear,
  .zoobbe-share {
    // background-color: var(--primary-text-color);
    // background-color: #6c757d;
    border: none;
    // color: var(--color-white);
    color: var(--white);
    // padding: 0px 10px;
    // margin-left: 10px;
    cursor: pointer;
    border-radius: 3px;
    height: 32px;
    font-size: 14px;
    font-family: system-ui;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.1s;
    font-weight: 600;
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;

    span {
      font-size: 18px;
    }

    .filter-indicator {
      width: 10px;
      height: 10px;
      background-color: var(--brand-color);
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: -8px;
    }
  }

  .filter-content {
    display: flex;
    align-items: center;
    // background: var(--primary-text-color);
    border-radius: var(--element-border-radius);
    gap: 30px;

    .cards-count {
      display: flex;
      align-items: center;
      gap: 6px;
      span {
        color: var(--primary-text-color);
        // font-weight: 600;
        font-size: 18px;
      }
    }

    span.count-number {
      font-size: 16px !important;
      font-weight: 600;
    }

    .cards-count.active span {
      color: var(--white) !important;
    }

    button {
      margin: 0;
    }

    button.cards-count {
      /* background: none; */
      border: none;
      border-radius: 50px;
      min-width: 15px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }

  .zoobbe-filters {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
  }

  .zoobbe-members {
    display: flex;
    align-items: center;
    // margin-left: 20px;

    .image-placeholder {
      // margin-left: -10px;
      // border: 2px solid #0000002b;
      user-select: none;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // margin-left: -10px;
      // border: 2px solid #0000002b;
      cursor: pointer;
      background-color: var(--white);
    }

    .zoobbe-more-members {
      align-items: center;
      border-radius: 50%;
      color: var(--primary-text-color);
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      height: 30px;
      justify-content: center;
      width: 30px;
      z-index: 2;
      background: var(--small-element-background-color) 7a;
      margin-left: 3px;
      user-select: none;
      transition: 0.2s;
      &:hover {
        background: #40485194;
      }
    }
  }

  .zoobbe-share {
    // margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
    span.material-symbols-outlined {
      font-size: 18px;
    }
  }

  button.zoobbe-board-more {
    display: flex;
    align-items: center;
    background: transparent;
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
    justify-content: end;
    padding: 0;
    // margin-left: 20px;
  }
}

// Card details styles
.zoobbe-card-wrapper {
  z-index: 2 !important;
}
.zoobbe-card-modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto; // Allow scrolling on the container
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  align-items: flex-start;

  .hidden {
    display: none !important;
  }

  .card-heading {
    display: flex;
    align-items: start;
    gap: 15px;
    margin-left: -35px;

    .textarea-wrapper {
      width: calc(100% - 80px);
      padding: 5px 0;
      border-radius: var(--element-border-radius);
      margin: -6px -10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .zoobbe-card-title-textarea {
        border-radius: var(--element-border-radius);
        box-shadow: none;
        font-size: 20px;
        font-weight: 600;
        padding: 0px 5px;
        resize: none;
        width: calc(100% - 20px);
        border: none;
        overflow: hidden;
        font-family: system-ui;
        background-color: var(--single-card-background-color);
        color: var(--single-card-text-color);
        margin: 0;
        cursor: text;

        &:focus {
          outline: 0;
        }
      }
    }
    .textarea-wrapper.focused {
      outline: 2px solid var(--focus-outline-color);
    }
  }

  .zoobbe-card-details {
    background-color: var(--single-card-background-color);
    width: 760px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: auto;
    max-width: 100%;
    position: relative;
    top: 0;
    min-height: 600px;
    color: var(--single-card-text-color);
    margin: 48px;

    .zoobbe-card-cover-image {
      position: relative;
      position: relative;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      max-height: 180px;
      img.cover-image {
        max-height: 180px;
        text-align: center;
        width: 100%;
        object-fit: contain;
      }
    }

    .zoobbe-card-content {
      padding: 20px;
      padding-left: 60px;
      padding-top: 15px;
    }

    .close-card-container {
      text-align: right;
      display: flex;
      justify-content: end;
      position: absolute;
      right: 15px;
      top: 20px;
      z-index: 1;
      .close-card {
        padding: 5px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        position: relative;
        top: -10px;
        right: -5px;
        color: var(--single-card-text-color);
        &:hover {
          background-color: var(--single-card-side-action-bg-color);
        }
      }
    }

    &-header {
      h2 {
        font-size: 20px;
        margin: 0;
        line-height: 1.4;
      }
    }

    &-status {
      color: var(--single-card-text-color);
      margin-bottom: 25px;
      margin-top: -5px;

      span.actionlist {
        font-weight: bold;
        cursor: pointer;
        margin-left: 5px;
        font-size: 15px;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-body {
      margin-top: 5px;

      .zoobbe-card-details-info {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .zoobbe-card-details-info-left {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: calc(100% - 180px);

          .card-details-top {
            display: flex;
            align-items: self-start;
            flex-wrap: wrap;
            gap: 20px 30px;
          }

          h5 {
            padding: 0;
            margin: 0;
            margin-bottom: 15px;
            font-size: 15px;

            color: var(--single-card-text-color);
            display: block;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            line-height: 20px;
            margin: 0 8px 5px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .editor-focused {
            outline: 2px solid var(--brand-color);
          }
        }

        .zoobbe-card-details-info-right {
          width: 180px;

          .window-sidebar {
            ul {
              padding: 0;
              margin: 0;
              list-style: none;
            }
            h3 {
              color: var(--single-card-text-color);
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              line-height: 20px;
              margin-top: 0;
            }
            button {
              display: flex;
              align-items: center;
              width: 100%;
              margin-top: 8px;
              padding: 10px 10px;
              gap: 6px;
              cursor: pointer;
              font-size: 14px;
              font-family: system-ui;
              font-weight: var(--single-card-side-action-font-weight);
              color: var(--single-card-text-color);
              background-color: var(--single-card-side-action-bg-color);
              transition: 0.2s;
              &:hover {
                background-color: var(--single-card-action-button-hover-color);
              }
              .material-symbols-outlined {
                font-size: 18px;
              }
            }
            button#popover-card-back-delete-card-button {
              background-color: var(--delete-button-bg-color);
              color: var(--delete-button-text-color);
              &:hover {
                background-color: darken($color: #f87168, $amount: 10%);
              }
            }
          }
          .button-link,
          button {
            background-color: var(--ds-background-neutral, #091e420f);
            border: none;
            border-radius: 3px;
            color: var(--ds-text, #172b4d);
            user-select: none;
          }
        }
      }

      .zoobbe-card-members {
        // span {
        //   margin-right: 5px;
        // }
        .member-list {
          display: inline-flex;
          align-items: center;
          gap: 3px;
          position: relative;
          .avatars {
            display: flex;
            align-items: center;
            position: relative;

            // .image-placeholder {
            //   border: 1px solid #a39ecf;
            // }
          }
          .add-member-to-card {
            width: 35px;
            height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: var(--single-card-side-action-bg-color);
            border-radius: 50%;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
              background-color: var(--single-card-action-button-hover-color);
            }
            span {
              color: var(--single-card-text-color);
              font-size: 22px;
            }
          }
        }

        img {
          width: 33px;
          height: 33px;
          border-radius: 50%;
        }
      }

      .zoobbe-labels {
        .zoobbe-label {
          background-color: #d1453b;
          color: var(--primary-text-color);
          padding: 2px 5px;
          border-radius: 3px;
          font-size: 0.9em;

          &.zoobbe-bug-fix {
            display: flex;
            position: relative;
            min-height: 35px;
            margin-bottom: 0;
            padding: 0px 12px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            align-items: center;
            min-width: 35px;
            border-radius: var(--element-border-radius);
            justify-content: center;
            cursor: pointer;
            opacity: 0.75;
            color: var(--label-text-color);
            font-weight: var(--label-font-weight);
            user-select: none;
            &:hover {
              opacity: 0.6;
            }
          }
        }
        .add-labels-to-card {
          display: flex;
          align-items: center;
          width: 35px;
          height: 35px;
          background-color: var(--single-card-side-action-bg-color);
          justify-content: center;
          border-radius: var(--element-border-radius);
          cursor: pointer;
          transition: 0.2s;
          user-select: none;
          transition: 0.2s;
          &:hover {
            background-color: var(--single-card-action-button-hover-color);
          }

          span {
            font-size: 22px;
          }
        }
      }

      .zoobbe-notifications {
        .notifications-content {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: var(--single-card-side-action-bg-color);
          height: 35px;
          padding: 0 12px 0 10px;
          border-radius: var(--element-border-radius);
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: var(--single-card-action-button-hover-color);
          }

          span.material-icons-outlined {
            font-size: 18px;
          }
          .watching-checkmark {
            background: var(--single-card-action-button-hover-color);
            border-radius: 2px;
            margin-left: 5px;
            margin-right: -7px;
            width: 28px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;

            span.material-symbols-outlined {
              font-size: 16px;
            }
          }
        }
        .zoobbe-watching {
          font-size: 0.9em;
        }
      }

      .duedate-content {
        display: flex;
        align-items: center;
        gap: 8px;

        .checkbox-container {
          padding: 0;
        }

        .duedate {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: var(--single-card-side-action-bg-color);
          height: 35px;
          padding: 0 6px 0 8px;
          border-radius: var(--element-border-radius);
          font-weight: 500;
          cursor: pointer;
          font-size: 14px;
          color: var(--single-card-text-color);

          &:hover {
            background-color: var(--single-card-action-button-hover-color);
          }

          span.completed-text {
            background: green;
            color: var(--label-text-color);
            padding: 0 6px;
            border-radius: var(--element-border-radius);
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: system-ui;
          }
          span.overdue-text {
            background: #ff6b6b;
            color: var(--label-text-color);
            padding: 0 6px;
            border-radius: var(--element-border-radius);
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: system-ui;
          }
        }
      }
      .priority-content {
        display: flex;
        align-items: center;
        gap: 8px;

        .priority {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: var(--single-card-side-action-bg-color);
          height: 35px;
          padding: 0 12px;
          border-radius: var(--element-border-radius);
          font-weight: 500;
          cursor: pointer;
          font-size: 14px;
          color: var(--single-card-text-color);

          svg {
            width: 18px;
            height: 18px;
            margin-left: -3px;
          }

          &:hover {
            background-color: var(--single-card-action-button-hover-color);
          }

          svg.zoobbe-flag-low {
            fill: rgb(96, 96, 96);
          }
          svg.zoobbe-flag-high {
            fill: rgb(255, 197, 61);
          }
          svg.zoobbe-flag-normal {
            fill: #3e63dd;
          }
          svg.zoobbe-flag-urgent {
            fill: rgb(255, 133, 137);
          }
        }
      }
    }

    .zoobbe-description,
    .zoobbe-attachments,
    .zoobbe-activity,
    .comment-section {
      .card-description-content {
        word-break: break-word;
        line-height: 1.6rem;
      }
      h3 {
        margin-bottom: 15px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 15px;
        margin-left: -40px;
        font-size: 16px;
        font-weight: var(--single-section-header-font-weight);
        font-family: system-ui;
        color: var(--single-card-text-color);
      }

      a {
        color: #0079bf;
        text-decoration: none;
        word-break: break-all;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .comment-section h3 {
      margin-left: 5px;
    }

    .description-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 0;
      }

      .edit-description-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        border: none;
        padding: 0px 14px 0 10px;
        background: var(--single-card-side-action-bg-color);
        margin-left: 10px;
        cursor: pointer;
        color: var(--single-card-text-color);
        border-radius: 3px;
        transition: 0.2s;
        height: 35px;
        font-family: system-ui;
        font-weight: 500;

        &:hover {
          background-color: var(--single-card-action-button-hover-color);
        }

        span {
          color: var(--single-card-text-color);
          font-size: 18px;
        }
      }
    }

    .activity-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      button {
        font-size: 14px;
        border: none;
        padding: 0px 12px;
        background: var(--single-card-side-action-bg-color);
        margin-left: 10px;
        cursor: pointer;
        color: var(--single-card-text-color);
        border-radius: 3px;
        transition: 0.2s;
        height: 35px;
        font-family: system-ui;
        font-weight: 500;

        &:hover {
          background-color: var(--single-card-action-button-hover-color);
        }
      }
    }

    // &-footer {
    //   margin-top: 20px;
    //   padding-top: 10px;
    // }
  }
}

/* For Webkit Browsers (Chrome, Safari) */
.action-list-container::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}

.action-list-container::-webkit-scrollbar-track {
  background: transparent; /* color of the scrollbar track */
}

.action-list-container::-webkit-scrollbar-thumb {
  background-color: var(--scrolbar-thumb-background-color); /* color of the scrollbar thumb */
  border-radius: 10px; /* roundness of the scrollbar thumb */
}

.action-list-container::-webkit-scrollbar-thumb:hover {
  background: var(--scrolbar-thumb-background-color); /* color of the scrollbar thumb when hovered */
}

/* For Firefox */
.action-list-container {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

/* For Internet Explorer and Edge */
.action-list-container::-ms-scrollbar {
  width: 4px;
}

.action-list-container::-ms-scrollbar-track {
  background: transparent;
}

.action-list-container::-ms-scrollbar-thumb {
  // background-color: #dddaff;
  border-radius: 10px;
}

// add action list button styles

.action-list-button-container {
  display: flex;
  align-items: self-start;
  justify-content: center;
  // flex: 0 0 280px;

  .add-list-button {
    display: flex;
    align-items: center;
    background-color: var(--add-list-background-color);
    border: none;
    border-radius: 15px;
    padding: 0px 15px;
    font-size: 16px;
    color: var(--primary-text-color);
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    gap: 8px;
    height: 45px;

    span.material-symbols-outlined {
      font-size: 20px;
    }

    &:hover {
      background-color: var(--add-list-background-hover-color);
    }

    .plus-icon {
      margin-right: 10px;
      font-size: 20px;
    }
  }
}

.card-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;

  .add-card-button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    // border-radius: 20px;
    padding: 8px 10px;
    font-size: 14px;
    color: #2d3e50;
    cursor: pointer;
    background: none;
    width: 100%;
    gap: 5px;
    font-size: 15px;
    color: var(--single-card-text-color);

    span.material-symbols-outlined {
      font-size: 18px;
    }

    &:hover {
      background-color: var(--add-card-button-hover-background-color);
      transition: background-color 0.3s ease;
    }

    .plus-icon {
      margin-right: 10px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
  }

  .card-icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    span.material-symbols-outlined {
      font-size: 18px;
      margin-right: 5px;
      color: var(--single-card-text-color);
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #2d3e50;
    }
  }
}

// Not found page style

.zoobbe-board-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  p {
    width: 500px;
    line-height: 1.6;
    font-size: 24px;
    color: #ddd;
  }
}
// index.scss

$primary-color: #0079bf;
$secondary-color: #61bd4f;
$text-color: var(--scrolbar-thumb-background-color);
$background-color: #f4f5f7;
$card-background: var(--primary-text-color);
$border-radius: 5px;
$box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

.zoobbe-workspace-container {
  // width: 90%;
  margin: 0 auto;
  padding: 20px;

  h1 {
    color: $text-color;
    font-size: 2em;
    margin-bottom: 20px;
  }
}

.workspace {
  background-color: $card-background;
  padding: 20px;
  border-radius: $border-radius;
  // box-shadow: $box-shadow;
  margin-bottom: 40px;

  .workspace-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .workspace-icon {
      background-color: $primary-color;
      color: var(--primary-text-color);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      margin-right: 15px;
    }

    h2 {
      flex-grow: 1;
      color: $primary-color;
      font-size: 1.5em;
    }

    .workspace-actions {
      display: flex;
      gap: 10px;

      .action-btn {
        background-color: #e0e0e0;
        border: none;
        padding: 5px 10px;
        border-radius: $border-radius;
        cursor: pointer;
        font-size: 0.9em;

        &:hover {
          background-color: darken(#e0e0e0, 10%);
        }
      }

      .upgrade-btn {
        background-color: #6c5ce7;
        color: var(--primary-text-color);

        &:hover {
          background-color: darken(#6c5ce7, 10%);
        }
      }
    }
  }

  .board-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .board {
      background-color: $background-color;
      border-radius: $border-radius;
      // box-shadow: $box-shadow;
      width: calc(33% - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      .board-link {
        color: $primary-color;
        text-decoration: none;
        font-size: 1.2em;
        padding: 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .add-board-btn {
    background-color: purple;
    color: var(--primary-text-color);
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;

    &:hover {
      background-color: darken(#e0e0e0, 10%);
    }
  }

  .board-form {
    textarea {
      padding: 10px;
      border-radius: $border-radius;
      border: 1px solid #ccc;
      font-size: 1em;
      margin-bottom: 10px;
      resize: none;
    }

    .add-btn {
      background-color: $secondary-color;
      color: var(--primary-text-color);
      border: none;
      padding: 10px;
      border-radius: $border-radius;
      cursor: pointer;
      font-size: 1em;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }
  }
}

.zoobbe-board,
.zoobbe-action-list,
.workspace {
  $form-background: #f9f9f9;
  $form-shadow-color: rgba(0, 0, 0, 0.1);
  $form-border-radius: 5px;

  $form-input-border: #ddd;
  $form-input-focus-border: var(--brand-color);
  $form-input-focus-shadow: rgba(0, 123, 255, 0.5);

  $form-button-bg: var(--brand-color);
  $form-button-color: var(--white);
  $form-button-hover-bg: #0056b3;
  $form-button-hover-color: var(--popover-background-color);
  $form-button-hover-bg-light: var(--popover-background-color);

  $form-button-svg-fill: $form-button-bg;
  $form-button-svg-hover-fill: $form-button-hover-bg;

  $card-details-background: var(--actionlist-background-color);

  form {
    display: flex;
    align-items: self-start;
    gap: 10px;
    background-color: var(--actionlist-background-color);
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
    height: max-content;
    flex: 0 0 260px;

    textarea {
      padding: 10px;
      border: 2px solid var(--actionlist-background-color);
      border-radius: 5px;
      font-size: 14px;
      resize: none;
      font-family: system-ui;
      width: calc(100% - 22px);
      height: 20px;
      background-color: var(--popover-background-color);
      color: var(--single-card-text-color);
      resize: none;
      overflow: hidden;
      min-height: 20px;

      &:focus {
        border-color: $form-input-focus-border;
        outline: none;
      }
      .add-new-list {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .footer-buttons {
      display: flex;
      gap: 10px;

      span.material-symbols-outlined {
        color: var(--single-card-text-color);
      }
    }
    button {
      background-color: $form-button-bg;
      color: $form-button-color;
      border: none;
      padding: 5px 15px;
      border-radius: $form-border-radius;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;

      &:hover {
        background-color: $form-button-hover-bg;
        color: var(--color-white);
      }

      &[type="button"] {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $form-button-hover-bg-light;
        }

        svg {
          fill: $form-button-svg-fill;
          transition: fill 0.3s;
          width: 20px;

          &:hover {
            fill: $form-button-svg-hover-fill;
          }
        }
      }

      &[type="submit"]:hover {
        background-color: $form-button-hover-bg;
      }
    }
  }
}

.zoobbe-action-list {
  .action-list-button-wrapper form {
    background: var(--actionlist-background-color);
    border-radius: 15px;
    width: auto !important;
  }

  form {
    padding: 0;
    box-shadow: none;
    background: none;
    textarea {
      width: calc(100% - 30px);
      min-height: 36px;
      // height: auto;
      margin: 0;
      padding: 8px 12px;
      overflow: hidden;
      overflow-y: auto;
      border: none;
      border-radius: 8px;
      background-color: var(--ds-surface-raised, var(--popover-background-color));
      box-shadow: var(
        --ds-shadow-raised,
        0px 1px 1px rgba(9, 30, 66, 0.2509803922),
        0px 0px 1px rgba(9, 30, 66, 0.3098039216)
      );
      resize: none;
      overflow-wrap: break-word;
      font-family: system-ui;
      border: 2px solid transparent;
      overflow: hidden;
      resize: none; // Prevent manual resizing
      line-height: 1.4;
      font-weight: 600;
    }

    textarea.add-new-list {
      // width: calc(100% - 48px) !important;
      min-height: 20px !important;
      font-family: system-ui;
    }
  }
}
.update-list-title,
.update-list-title::placeholder {
  display: inline;
  width: auto;
  background: #06060600;
  border: none;
  color: var(--single-card-text-color);
  font-size: 15px;
  font-weight: 600;
  resize: none;
  overflow: hidden;
  min-height: 20px;
  font-family: system-ui;
  padding: 2px;
  &::placeholder {
    font-family: system-ui;
    color: var(--single-card-text-color);
    font-size: 15px;
    font-weight: 600;
  }
}

span.update-list-title-wrapper {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    cursor: pointer;
  }
}

span.update-list-title-wrapper.editing {
  position: relative;
  &::after {
    display: none;
  }
}
.update-list-title {
  border-radius: 6px;
  width: 100%;
  line-height: 1.45;
  color: var(--primary-text-color);
  font-size: 15px;
  font-weight: 500;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  &:focus {
    outline: none;
    box-shadow: none;
    outline: 2px solid var(--brand-color);
  }
}

span.card-filters-count {
  color: #6c757d;
  font-size: 12px;
  font-family: system-ui;
}

.workspace {
  form {
    max-width: max-content;
    margin-top: 10px;
  }
}

.action-list-option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  .action-list-option-icon {
    cursor: pointer;
    display: inline-flex;
    // margin-top: 4px;

    span {
      color: var(--single-card-text-color);
      font-size: 16px;
    }

    span.material-symbols-outlined {
      font-size: 20px;
    }
    &:hover span {
      color: var(--brand-color);
    }
  }

  .list-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--primary-text-color);
    /* border: 1px solid #ccc; */
    border-radius: var(--element-border-radius);
    box-shadow: var(--popover-box-shadow);
    overflow: hidden;
    transition:
      max-height 0.3s ease,
      opacity 0.3s ease,
      visibility 0.3s;
    z-index: 10;

    a {
      display: block;
      padding: 10px 15px;
      color: var(--scrolbar-thumb-background-color);
      text-decoration: none;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  &:focus .list-options,
  &:active .list-options,
  .list-options:hover {
    max-height: 200px; // Adjust this value as needed
    opacity: 1;
    visibility: visible;
  }
}

#zoobbe-board {
  .scrolling .card-pemalink-wrapper:hover {
    border-color: transparent;
  }
  .scrolling .card-pemalink-wrapper:hover .edit-card {
    display: none;
  }
}

#zoobbe-board {
  position: relative;
  width: 100%;
  height: calc(100vh - 52px); /* Full viewport height */
  background-image: var(--background-url); /* Use custom CSS property */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0; /* Place it below all content */

  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  /* Apply the background image dynamically */
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: var(--background-url); /* Use custom CSS property */
  //   background-size: cover;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   z-index: 0; /* Place it below all content */
  // }

  // /* Add overlay on top of the background image */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--board-overlay-background-color); /* Semi-transparent overlay */
    // background-color: #00000032;
    z-index: 0; /* Same layer as the background */
  }

  /* Ensure content is not affected by pseudo-elements */
  > * {
    position: relative;
    z-index: 1;
  }
}

@media only screen and (max-width: 768px) {
  .zoobbe-card-modal-container .zoobbe-card-details {
    max-width: calc(100% - 60px);
  }

  .zoobbe-card-modal-container .zoobbe-card-details-body .zoobbe-card-details-info {
    flex-direction: column;
  }
  .zoobbe-card-modal-container .zoobbe-card-details-body .zoobbe-card-details-info .zoobbe-card-details-info-left {
    width: 100%;
  }
  .zoobbe-card-modal-container .zoobbe-card-details-body .zoobbe-card-details-info .zoobbe-card-details-info-right {
    width: 100%;
  }

  .zoobbe-card-modal-container
    .zoobbe-card-details-body
    .zoobbe-card-details-info
    .zoobbe-card-details-info-right
    .window-sidebar
    ul {
    display: grid;
    width: 100%; /* Make sure the grid spans the full width of the container */
    grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
    gap: 8px;
  }

  .zoobbe-card-modal-container
    .zoobbe-card-details-body
    .zoobbe-card-details-info
    .zoobbe-card-details-info-right
    .window-sidebar
    button {
    margin-top: 0;
  }
}
