.activities {
    margin-left: -45px;

    .comment-section {
        margin-left: 0;
    }

    .activity {
        display: flex;
        align-items: flex-start;
        padding: 10px 0 0 10px;
        border-radius: 8px;
        gap: 10px;
        margin-bottom: 15px;

        .activity__profile-img {
            border-radius: 50%;
            margin-right: 10px;
            width: 35px;
            height: 35px;
        }

        .activity__content {
            flex: 1;

            .activity__header {
                display: flex;
                // align-items: center;
                gap: 8px;
                font-size: 14px;
                margin-bottom: 5px;
                color: var(--primary-text-color); /* Uses the primary text color */
                font-weight: 500;

                strong {
                    color: var(--single-card-text-color); /* Uses the strong text color */
                    white-space: nowrap;
                }

                .activity__details {
                    word-break: break-word;

                    a {
                        font-weight: 700;
                        text-decoration: none;
                        color: var(--primary-text-link-color); /* Uses the brand color */
                    }
                    p{
                        margin-top: 0;
                    }
                }
            }

            span {
                font-size: 12px;
                color: var(--activity-text-color); /* Uses the activity text color */
                font-weight: 500;
            }
        }
    }
}
