.zoobbe-card-details .react-loading-skeleton {
    --base-color: var(--single-card-side-action-bg-color) !important;
    --highlight-color: #17171a36;
}

.zoobbe-card-wrapper.skeleton {
    .card-heading {
        margin-bottom: 10px;
    }
    .card-members {
        display: flex;
        gap: 5px;
    }

    .watchers {
        display: flex;
        gap: 8px;
    }

    .zoobbe-attachment {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .zoobbe-duedate {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .zoobbe-card-details-info-right span.react-loading-skeleton {
        margin-bottom: 8px;
    }

    .zoobbe-card-content {
        display: flex;
        gap: 10px;
    }

    .zoobbe-card-modal-container .zoobbe-card-details-body {
        width: 100%;
    }

    .zoobbe-card-modal-container .card-heading {
        justify-content: space-around;
    }
}
