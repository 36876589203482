.share-card {
    padding: 15px;
    width: 280px;
    h2 {
        margin-bottom: 20px;
        margin-top: 0px;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }

    input,
    select {
        width: calc(100% - 20px);
        padding: 0 10px;
        background-color: var(--input-background-color);
        // outline: 1px solid var(--outline-color);
        outline: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        color: var(--single-card-text-color);
        font-size: 14px;
        border: none;
        height: 35px;
        &:focus {
            outline: 2px solid var(--focus-outline-color);
        }
    }

    .zoobbe-select {
        width: 100%;
        margin-bottom: 0;
    }

    button {
        padding: 10px 20px;
        background-color: var(--brand-color);
        border: none;
        border-radius: var(--element-border-radius);
        color: var(--white);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #125fcc;
        }
    }
}
