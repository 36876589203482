.add-attachment {
    padding: 15px;
    width: 280px;
    h2 {
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        margin-bottom: 20px;
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 14px;
            margin-top: 0;
        }
        p {
            font-size: 14px;
            font-family: system-ui;
            font-weight: 500;
            line-height: 1.4rem;
        }

        &:last-child {
            margin: 0;
        }

        .textarea-wrapper {
            width: calc(100% - 20px);
            padding: 6px 10px;
            background-color: var(--popover-background-color);
            outline: 1px solid var(--secondary-outline-color);
            border-radius: var(--element-border-radius);
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            textarea {
                background-color: var(--popover-background-color);
                border: none;
                border: none;
                width: 100%;
                color: var(--single-card-text-color);
                font-size: 14px;
                font-weight: var(--single-card-side-action-font-weight);
                line-height: 1.2rem;
                padding: 0;
                resize: none;
                font-family: system-ui;
                &:focus {
                    outline: none;
                }
            }
        }

        .textarea-wrapper.focused {
            outline: 2px solid var(--focus-outline-color);
        }
    }

    button.attach-file-btn {
        width: 100%;
        height: 35px;
        background: var();
        padding: 0;
    }

    input,
    select {
        width: calc(100% - 20px);
        padding: 0 10px;
        background-color: var(--popover-background-color);
        border: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        color: var(--single-card-text-color);
        font-size: 14px;
        height: 35px;
        font-weight: var(--single-card-side-action-font-weight);
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .zoobbe-select {
        width: 100%;
        margin-bottom: 0;
    }

    button {
        padding: 10px 20px;
        background-color: var(--brand-color);
        border: none;
        border-radius: var(--element-border-radius);
        color: var(--white);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #125fcc;
        }
    }
}

button#popover-add-editor-image {
    margin-right: auto;
}