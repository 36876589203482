.profile-card {
    width: 310px;
    // outline: 1px solid #5c7299;
    gap: 10px;
    padding-bottom: 5px;
    border-radius: 7px 7px 0 0;
    overflow: hidden;

    .profile-cover {
        height: 80px;
        background: linear-gradient(15deg, #0966ff, #6c757d);
    }

    &__content {
        display: flex;
        align-items: center;
        // padding: 30px 10px;
        // background: var(--brand-color);
        // background: linear-gradient(15deg, #0966ff, #6c757d);
        flex-direction: column;
        margin-top: -35px;
        gap: 10px;
        // background: #579dff;
        // background: #0966ff;
 
        img,
        .image-placeholder {
            border-radius: 50%;
            // margin-bottom: -35px;
            position: relative;
            border: 5px solid var(--popover-background-color);
        }

        .image-placeholder {
            font-size: 32px !important;
            user-select: none;
            position: relative;
        }

        & .profile-card__avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 16px;
        }

        & .profile-card__info {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-bottom: 20px;

            & .profile-card__name {
                margin: 0;
                color: var(--primary-text-color);
                font-size: 24px;
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                margin-bottom: 2px;
                font-weight: 500;

                span {
                    font-size: 16px;
                    color: var(--brand-color);
                }
            }

            & .profile-card__username {
                margin: 4px 0 0 0;
                color: var(--primary-text-color);
                font-size: 14px;
            }
        }
    }

    &__actions {
        // border-top: 1px solid var(--popover-border-color);
        padding: 5px 0;
        padding-bottom: 0;
        display: flex;
        gap: 5px;

        & .profile-card__action {
            display: block;
            width: 100%;
            text-align: left;
            background: none;
            border: none;
            color: var(--primary-text-color);
            font-family: system-ui;
            cursor: pointer;
            padding: 8px 15px;

            &:hover {
                background-color: var(--profile-card-action-background-hover-color);
                color: var(--brand-color);
            }

            display: block;
            width: calc(100% - 10px);
            text-align: left;
            background: none;
            border: none;
            font-family: system-ui;
            cursor: pointer;
            padding: 8px 15px;
            // margin: 0 5px;
            border-radius: 5px;
            text-align: center;
            font-weight: 500;
        }
        .remove-from-card {
            background: var(--brand-color);
            background: var(--profile-card-action-background-hover-color);
            margin-right: 5px;
        }
        .edit-profile {
            color: var(--primary-text-color);
            background: var(--profile-card-action-background-hover-color);
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            span {
                font-size: 20px;
            }
        }
    }
}
