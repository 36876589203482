.zoobbe-main-content {
  margin: 0 auto;
  .collaborators {
    color: var(--white-text-color-alternative);
    display: flex;
    gap: 20px;
    max-width: 1180px;
    margin: auto;

    .collaborators-content {
      width: 100%;
    }

    h2 {
      margin-top: 0;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .tabs {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
      gap: 10px;
      width: 280px;

      .tab {
        padding: 10px 15px;
        border: 1px solid var(--input-border-color);
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s;

        &.selected {
          background-color: var(--colaborators-tab-background-color);
          color: var(--white-text-color-alternative);
        }

        &:not(.selected) {
          background-color: var(--top-header-background-color);
          color: var(--primary-text-color);
        }
      }
    }

    .workspace-members {
      h3 {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        margin-bottom: 10px;

        .icon-copy {
          margin-left: 10px;
          // background: url("copy-icon.svg") no-repeat center center;
          width: 16px;
          height: 16px;
        }
      }

      p {
        margin-bottom: 20px;
        color: #bbb;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
      }

      .invite {
        margin-bottom: 20px;

        h4 {
          font-size: 1rem;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 10px;
        }

        .invite-link-button {
          background-color: var(--brand-color);
          color: var(--white);
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background 0.3s;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-family: system-ui;

          span {
            font-size: 20px;
          }

          &:hover {
            background-color: #357ab8;
          }
        }
      }

      .members-list {
        input {
          width: 100%;
          padding: 0 10px;
          border-radius: 5px;
          border: 1px solid var(--input-border-color);
          background-color: var(--filter-placeholder-background-color);
          color: var(--white-text-color-alternative);
          margin-bottom: 20px;
          width: calc(100% - 20px);
          height: 35px;

          &:focus {
            outline: 2px solid var(--brand-color);
          }
        }

        .member {
          display: flex;
          align-items: center;
          padding: 15px 0;

          &:last-child {
            border: none;
          }

          .profile-pic {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .image-placeholder {
            margin-right: 10px;
            user-select: none;
          }

          .member-info {
            flex-grow: 1;
            .member-name {
              display: block;
              margin-bottom: 2px;
            }

            .member-bottom {
              display: flex;
              align-items: center;
              gap: 8px;

              .last-active,
              .username {
                font-size: 0.8rem;
                color: #888;
              }
            }
          }

          .view-boards-button {
            background-color: transparent;
            color: var(--single-card-text-color);
            padding: 0px 10px;
            border: none;
            border-radius: var(--element-border-radius);
            margin-right: 10px;
            cursor: pointer;
            transition: background 0.3s;
            height: 35px;
            font-size: 14px;
            display: flex;
            align-items: center;
            font-family: system-ui;
            outline: 1px solid var(--outline-color);

            &:hover {
              color: var(--brand-color);
            }
          }

          .admin-actions {
            display: flex;
            align-items: center;

            .admin-role {
              margin-right: 10px;
              font-weight: bold;
              margin-bottom: 0;
              width: 120px;

              .zoobbe-select-trigger {
                padding: 0px 12px;
                background-color: transparent;
                font-weight: 400;
                border: none;
                span.arrow-down {
                  top: 6px;
                }

                span {
                  right: 10px;
                }
              }

              .zoobbe-select-trigger.active {
                color: var(--brand-color);
              }

              .zoobbe-select-option {
                padding: 0px 12px;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                font-family: system-ui;
              }
            }

            .leave-button {
              background-color: transparent;
              color: var(--single-card-text-color);
              padding: 0px 8px;
              border: none;
              border-radius: 2px;
              cursor: pointer;
              transition: background 0.3s;
              height: 35px;
              display: flex;
              align-items: center;
              font-size: 14px;
              gap: 5px;
              padding-right: 10px;
              border-radius: var(--element-border-radius);
              outline: 1px solid var(--outline-color);
              span {
                font-size: 16px;
              }

              &:hover {
                background-color: #c4352c;
              }
            }
          }
        }
      }
    }

    .guests,
    .join-requests {
      p {
        margin-bottom: 20px;
        color: #bbb;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .zoobbe-main-content .collaborators {
    flex-direction: column;
  }

  .zoobbe-main-content .collaborators .tabs {
    flex-direction: row;
    width: 100%;
    margin: 0;
  }
}
