li.zoobbe-select-group {
    padding: 5px 12px;
    label {
        font-size: 12px;
        text-transform: uppercase;
    }
}

li.zoobbe-select-option {
    font-size: 12px;
    font-family: system-ui;
}

ul.board-options {
    padding: 0;
    list-style: none;
    margin-left: -10px;
}

.copy-card {
    padding: 15px;
    width: 280px;
    h2 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        margin-bottom: 20px;
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }
    input,
    select {
        width: calc(100% - 20px);
        padding: 0 10px;
        background-color: var(--popover-background-color);
        border: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        color: var(--single-card-text-color);
        font-size: 14px;
        height: 35px;
        font-weight: var(--single-card-side-action-font-weight);
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    .textarea-wrapper {
        width: calc(100% - 20px);
        padding: 6px 10px;
        background-color: var(--popover-background-color);
        outline: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        display: flex;
        align-items: center;

        textarea {
            background-color: var(--popover-background-color);
            border: none;
            background-color: var(--popover-background-color);
            border: none;
            width: 100%;
            color: var(--single-card-text-color);
            font-size: 14px;
            font-weight: var(--single-card-side-action-font-weight);
            line-height: 1.2rem;
            padding: 0;
            resize: none;
            font-family: system-ui;

            &:focus {
                outline: none;
            }
        }
    }
    .textarea-wrapper.focused {
        outline: 2px solid var(--focus-outline-color);
    }

    .zoobbe-select {
        width: 100%;
        margin-bottom: 0;
    }

    .keep-members {
        display: flex;
        align-items: center;
        gap: 10px;

        span.label {
            font-weight: 600;
            font-size: 14px;
            font-family: system-ui;
            cursor: pointer;
            user-select: none;
        }
    }

    button {
        padding: 10px 20px;
        background-color: var(--brand-color);
        border: none;
        border-radius: var(--element-border-radius);
        color: var(--white);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #125fcc;
        }
    }
}

.actionlists-group {
    display: flex;
    gap: 10px;

    .group.actionlists {
        width: calc(100% - 90px);
    }

    .group.position {
        width: 80px;
    }
}
