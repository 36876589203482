.filter-container {
    color: var(--white-text-color-alternative);
    padding: 15px 20px;
    width: 300px;
    border-radius: 8px;
    width: 300px;
    background-color: var(--popover-background-color);
    border-radius: 8px;
    position: absolute;
    right: -15px;
    top: 42px;
    z-index: 1;
    overflow: auto;
    z-index: 3;
    border: 1px solid var(--popover-border-color);
    box-shadow: var(--popover-box-shadow);

    input {
        width: calc(100% - 30px);
        height: 35px;
        margin: 0;
        padding: 0px 12px;
        overflow: hidden;
        overflow-y: auto;
        border: none;
        border-radius: var(--element-border-radius);
        background-color: var(--filter-placeholder-background-color);
        box-shadow: none;
        resize: none;
        overflow-wrap: break-word;
        font-family: system-ui;
        // border: 2px solid transparent;
        overflow: hidden;
        resize: none;
        line-height: 1.4;
        outline: 0;
        color: var(--filter-text-color-muted);
        margin-bottom: 5px;

        &:focus {
            // border: 2px solid #017bff !important;
            outline: 0;
            box-shadow: none;
        }
    }
    .keyword {
        outline: 1px solid var(--input-field-border-color);
        width: calc(100% - 25px);

        &:focus {
            outline: 2px solid var(--brand-color);
        }
    }

    .label {
        color: var(--filter-label-text-color);
    }
}

h3.filter-title-wrapper {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 0;

    .filter-title {
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }
    span.material-symbols-outlined.clear-all {
        cursor: pointer;
    }
    span.material-symbols-outlined.filter-close {
        cursor: pointer;
        font-size: 22px;
    }
}

.filter-section {
    margin-bottom: 20px;

    span.material-symbols-outlined {
        padding: 5px;
        background: var(--filter-icon-background);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    span.material-symbols-outlined.person {
        background: var(--filter-icon-person-background);
    }
    span.material-symbols-outlined.overdue {
        background: var(--filter-icon-overdue-background);
    }
    span.material-symbols-outlined.due-nextday {
        background: var(--filter-icon-due-nextday-background);
    }

    .image-placeholder {
        font-size: 10px !important;
    }

    h4 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: var(--filter-text-color-muted);
    }
}

.filter-options {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;

    .label-color {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: var(--element-border-radius);
    }

    span {
        // margin-left: 10px;
        font-size: 14px;
        color: var(--filter-option-text-color);
    }

    .show-more {
        color: var(--brand-color);
        text-decoration: none;
        font-size: 14px;
    }
}

.checkbox-icon {
    color: var(--filter-option-text-color);
}

.checkbox-checked-icon {
    color: var(--white-text-color-alternative);
}

button.zoobbe-filters span.material-symbols-outlined {
    font-size: 18px;
    // margin-right: 5px;
}

span.search-description {
    font-size: 12px;
    color: var(--filter-option-text-color);
    font-weight: 400;
    font-family: system-ui;
}

.select-members {
    position: relative;
    width: 100%;

    .sbm-search-results .label {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: 0.2s;
        padding: 4px;

        &:hover {
            background: var(--search-results-option-background-color);
            border-radius: 5px;
        }
    }

    .mention-member {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;

        img {
            border-radius: 50%;
        }
    }

    // members popover style

    .sbm-search-results {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: var(--popover-background-color);
        border: 1px solid var(--popover-border-color);
        border-radius: var(--element-border-radius);
        width: calc(100% - 12px);
        z-index: 1000;
        padding: 5px;
        max-height: 500px;
        overflow: auto;
        margin-top: 5px;
        box-shadow: var(--popover-box-shadow);
    }

    .mention-suggestion {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
    }

    .mention-suggestion {
        transition: 0.2s;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 12px 10px;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 4px;
        gap: 12px;
    }

    .mention-suggestion img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .mention-suggestion .suggession-name {
        font-weight: 600;
    }

    .suggession-name,
    .suggession-handler {
        font-size: 14px;
        line-height: 1.4;
        color: var(--single-card-text-color);
        user-select: none;
    }
}

.select-option-label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: var(--filter-option-text-color);
    font-family: system-ui;
    justify-content: space-between;
    position: relative;
    background-color: var(--filter-placeholder-background-color);
    outline: 1px solid var(--input-field-border-color);
    border-radius: var(--element-border-radius);
    cursor: pointer;

    input {
        margin-bottom: 0;
    }

    &:focus {
        outline: 2px solid var(--brand-color) !important;
    }

    span.material-symbols-outlined {
        background: none;
    }
}

.select-option-label.focus {
    outline: 2px solid var(--brand-color);
    span {
        color: var(--filter-icon-person-background);
    }
}

span.material-symbols-outlined.check-mark {
    padding: 2px;
}

.checkbox {
    width: 12px;
    height: 12px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

button.cards-count {
    display: inline-block; // Makes it easier to control dimensions
    padding-left: 2px;
    padding-right: 4px;
    gap: 2px;
    height: 18px;
}

button.cards-count span.material-symbols-outlined {
    font-size: 14px;
    color: var(--filter-cards-count-text-color);
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 14px;
    height: 14px;
    background: var(--color-white);
    border-radius: 50px;
}
button.cards-count span.count-number {
    font-size: 13px;
    font-weight: 700;
    color: var(--filter-cards-count-text-color);
}

.spin {
    animation: spin 2s linear infinite; // Spin animation
}
