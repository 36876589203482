li.zoobbe-select-group {
    padding: 5px 12px;
    label {
        font-size: 12px;
        text-transform: uppercase;
    }
}

li.zoobbe-select-option {
    font-size: 12px;
    font-family: system-ui;
}

ul.board-options {
    padding: 0;
    list-style: none;
    margin-left: -10px;
}

.move-card {
    padding: 15px;
    width: 280px;
    h2 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        margin-bottom: 20px;
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }

    input,
    select {
        width: calc(100% - 20px);
        padding: 0 10px;
        background-color: #3b3b3b;
        border: 1px solid var(--outline-color);
        border-radius: var(--element-border-radius);
        color: var(--white-text-color-alternative);
        font-size: 14px;
        height: 35px;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .zoobbe-select {
        width: 100%;
        margin-bottom: 0;
    }

    button {
        padding: 0px 20px;
        background-color: var(--brand-color);
        border: none;
        border-radius: var(--element-border-radius);
        color: var(--white);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        height: 35px;
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #125fcc;
        }
    }
}

.actionlists-group {
    display: flex;
    gap: 10px;

    .group.actionlists {
        width: calc(100% - 90px);
    }

    .group.position {
        width: 80px;
    }
}
