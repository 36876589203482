// DynamicPopover.scss
// .dynamic-popover {
//     position: absolute;
//     background: var(--white-text-color-alternative);
//     border: 1px solid #ccc;
//     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//     z-index: 1000;
//     padding: 10px;
//     border-radius: var(--element-border-radius);
// }

::selection {
    color: var(--input-selection-color);
    background: var(--input-selection-bgcolor);
}
.zoobbe-popover {
    background-color: var(--popover-background-color);
    border-radius: 8px;
    color: var(--single-card-text-color);
    position: absolute;
    z-index: 1000;
    left: 0px;
    top: 45px;
    border-radius: 8px;
    // overflow: hidden;
    // transition: opacity 0.1s ease-in-out;
    border: 1px solid var(--popover-border-color);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        position: absolute;
        right: 6px;
        top: 6px;

        span.icon-close {
            font-size: 18px;
        }
        h3 {
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            font-family: system-ui;
            color: var(--single-card-text-color);
        }

        &__close-btn {
            background: none;
            border: none;
            color: var(--white-text-color-alternative);
            font-size: 24px;
            cursor: pointer;
        }

        button.zoobbe-popover__close-btn {
            background: none;
            border: none;
            box-shadow: none;
            color: var(--single-card-text-color);
            font-size: 20px;
            cursor: pointer;
            border-radius: 5px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s;

            &:hover {
                color: var(--single-card-text-color);
            }
        }
    }

    &__content {
        // margin-bottom: 16px;

        img{
            background-color: var(--white);
        }

        h4 {
            margin-bottom: 15px;
            color: var(--single-card-text-color);
            font-size: 14px;
            font-family: system-ui;
        }
    }
}

.zoobbe-popover {
    // opacity: 0;
    // transition: opacity 0.1s ease-in-out;
    position: absolute;
    // background: var(--white-text-color-alternative);
    // border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.zoobbe-popover.loaded {
    opacity: 1;
}
