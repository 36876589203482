.zoobbe-main-content {
  .board-header {
    display: flex;
    align-items: center;
    background-color: var(--single-card-side-action-bg-color);
    padding: 10px;
    border-radius: 8px;
    color: var(--white-text-color-alternative);
    margin-bottom: 30px;

    .board-icon {
      background-color: #f05a28;
      border-radius: var(--element-border-radius);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-text {
        color: var(--white-text-color-alternative);
        font-size: 24px;
        font-weight: bold;
      }
    }

    .board-details {
      margin-left: 10px;

      .board-name {
        display: flex;
        align-items: center;

        span {
          font-size: 20px;
          font-weight: 600;
        }

        span.material-symbols-outlined {
          margin-left: 5px;
          cursor: pointer;
          color: #bbbbbb;
          transform: rotate(-15deg);
        }
      }

      .board-visibility {
        display: flex;
        align-items: center;
        margin-top: 5px;

        span.material-symbols-outlined {
          font-size: 14px !important;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          color: #bbbbbb;
        }
      }
    }
  }

  .boards-content-header {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #4c3c3c;
    h4.header-title {
      font-size: 18px;
      line-height: 0;
      color: #ccc;
    }
  }
  .boards-page-board-section-list {
    // margin-left: 30px;
    display: grid;
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    .board-tile {
      padding: 10px 40px;
      display: flex;
      background: var(--scrolbar-thumb-background-color);
      text-decoration: none;
      color: #ddd;
      height: 50px;
      border-radius: 7px;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
    .create-board {
      padding: 10px 20px;
      display: flex;
      background: var(--board-item-background-color);
      text-decoration: none;
      color: #ddd;
      height: 50px;
      border-radius: 7px;
      align-items: center;
      cursor: pointer;
      user-select: none;
      text-align: center;
      justify-content: center;
      height: 100px;
    }
  }

  .boards-page-board-section-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .zoobbe-workspace-actions a {
      background-color: #293138;
      border-radius: 3px;
      color: var(--single-card-text-color);
      display: inline-block;
      font-weight: 500;
      padding: 8px 12px;
      position: relative;
      text-decoration: none;
      font-family: system-ui;
      font-size: 14px;
    }
  }

  .zoobbe-workspace-card {
    display: flex;
    align-items: center;
    .zoobbe-workspace-icon {
      width: 24px;
      height: 24px;
      // background-color: #ff5722;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .zoobbe-workspace-title {
      font-weight: bold;
    }
  }
  .zoobbe-workspace-actions {
    button {
      margin-left: 10px;
      background: none;
      border: none;
      color: var(--white-text-color-alternative);
      padding: 5px 10px;
      font-family: system-ui;
      &:hover {
        background-color: #5a5a5a;
        border-radius: 5px;
      }
    }
  }
}
