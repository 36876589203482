/* SnackBar.css */
.snackbar {
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 16px;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    background-color: #3b3b3b; /* Default background color */
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(100%);
    animation: slideUp 0.2s ease-out forwards; /* Slide up animation when opening */
    width: 200px;
    height: 50px;
    padding: 0 15px;
}

.snackbar-info {
    background-color: #2196f3; /* Blue */
}

.snackbar-success {
    background-color: #4caf50; /* Green */
}

.snackbar-error {
    background-color: #f44336; /* Red */
}

.snackbar-close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
}

.snackbar-close-btn:hover {
    opacity: 0.8;
}

/* Slide-up animation when appearing */
@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

/* Slide-down animation when closing */
@keyframes slideDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

/* Hide animation class */
.snackbar-exit {
    animation: slideDown 0.2s ease-in forwards; /* Slide down animation when hiding */
}
